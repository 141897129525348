import React from 'react';
import { motion } from 'framer-motion';
import { FaGooglePlay, FaYoutube } from 'react-icons/fa6';
import { FaApple } from 'react-icons/fa6';

const OurProducts = () => {
    return (
        <motion.section
            id='products'
            className='bg-black text-white py-12 px-4 sm:px-8 lg:px-16 xl:px-20'
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
        >
            <div className='max-w-6xl mx-auto'>
                <h1 className='text-4xl font-bold mb-8 text-center'>Our Products</h1>
                <p className='text-lg leading-relaxed mb-8 text-balance text-center'>
                    These products stand out as groundbreaking in their field, indicative of the innovative approach to software development followed by SparklingApps. 
                    The main focus of the products has been in the field of recognition.
                    Supporting all popular platforms for development.
                    Delivered apps for iOS, Android, Windows, and Facebook.
                </p>
                <div className="flex justify-center space-x-8">
                    <a href="https://play.google.com/store/apps/developer?id=Sparkling+Design+and+Infotech+Pvt.+Ltd." className="text-white hover:text-blue-400 transition duration-300 transform hover:scale-110"><FaGooglePlay className="text-5xl" /></a>
                    <a href="https://apps.apple.com/in/developer/sparkling-design-and-infotech-private-limited/id1711169094" className="text-white hover:text-blue-400 transition duration-300 transform hover:scale-110"><FaApple className="text-5xl" /></a>
                    <a href="https://www.youtube.com/user/sparklingapps/videos" className="text-white hover:text-blue-400 transition duration-300 transform hover:scale-110"><FaYoutube className="text-5xl" /></a>
                </div>
            </div>
        </motion.section>
    );
}

export default OurProducts;
