import React from 'react'
import AboutUs from './AboutUs'
import AppSection from './AppSection'
import Footer from './Footer'
import Header from './Header'
import HowToReachUs from './HowToReachUs'
import Intro from './Intro'
import LatestNews from './LatestNews'
import LeaveMessage from './LeaveMessage'
import MeetTheTeam from './MeetTheTeam'
import OurProducts from './OurProducts'
import OurStrengths from './OurStrengths'
import Video from './Video'

const SparklingApps = ({ posts }) => {
    // Sort posts by date
    // const sortedPosts = posts.sort((a, b) => new Date(b.date) - new Date(a.date));
    // const [loading, setLoading] = useState(true);

    // useEffect(() => {
    //     // Simulate a loading time
    //     const timer = setTimeout(() => {
    //         setLoading(false);
    //     }, 2000); // Adjust the time as needed

    //     return () => clearTimeout(timer);
    // }, []);

    // if (loading) {
    //     return <LottieLoader />;
    // }

    // Select the first four posts
    const latestPosts = posts.slice(0, 4);
    return (<div className="App bg-black">
        <Header isHome={true} />
        <Video />
        <Intro />
        <OurProducts />
        <AppSection />
        <AboutUs />
        <OurStrengths />
        <MeetTheTeam />
        <LatestNews posts={latestPosts} />
        <HowToReachUs />
        <LeaveMessage />
        <Footer />
    </div>
    )
}

export default SparklingApps