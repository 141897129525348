import React from 'react';
import { FaApple, FaGooglePlay, FaYoutube } from 'react-icons/fa';
import { motion } from 'framer-motion';

export const Intro = () => {
    return (
        <motion.div
            className="bg-gray-900 text-white py-8 px-4 md:px-8"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
        >
            <div className="max-w-4xl mx-auto text-center">
                <h1 className="text-4xl font-bold mb-6">Welcome to Sparkling Apps</h1>
                <p className="text-lg leading-relaxed mb-6">
                    SparklingApps is a lean enterprise that has jumped on the possibilities in the current market and technologies. The mobile technology and social media domains offer unique opportunities. SparklingApps was founded to develop applications that cater to these opportunities. The founder Martijn van der Spek has an exceptional technical background and has over 35 years of experience in software development.
                </p>
            </div>
        </motion.div>
    );
};

export default Intro;
