import React, { useState } from 'react';
import { motion } from 'framer-motion';

const LeaveMessage = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        const mailtoLink = `mailto:support@sparklingapps.com?subject=Message%20from%20Contact%20Form&body=Name:%20${encodeURIComponent(name)}%0D%0AEmail:%20${encodeURIComponent(email)}%0D%0AMessage:%20${encodeURIComponent(message)}`;
        window.location.href = mailtoLink;
    };

    return (
        <motion.section 
            className="bg-gray-900 text-white py-16"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
        >
            <div className="container mx-auto px-4 lg:px-8 flex flex-wrap items-center justify-center">
            <div className="w-full lg:w-1/3 mb-6 lg:mb-0">
                    <motion.img 
                        src="https://www.sparklingapps.com/img/about.png" 
                        alt="Company Logo" 
                        className="h-auto max-w-full rounded-lg "
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                    />
                </div>
                <motion.div 
                    className="w-full lg:w-2/3"
                    initial={{ y: 50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.8 }}
                >
                    <div className="max-w-lg mx-auto bg-gray-800 p-8 rounded-lg shadow-lg">
                        <h2 className="text-3xl font-bold mb-6 text-center">Leave Us a Message</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="grid grid-cols-1 gap-6">
                                <div>
                                    <label htmlFor="name" className="block text-sm font-semibold mb-2">Name</label>
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        autoComplete="name"
                                        className="w-full p-3 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        placeholder="Your Name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                    />
                                </div>
                                <div>
                                    <label htmlFor="email" className="block text-sm font-semibold mb-2">Email</label>
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        autoComplete="email"
                                        className="w-full p-3 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        placeholder="Your Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                                <div>
                                    <label htmlFor="message" className="block text-sm font-semibold mb-2">Message</label>
                                    <textarea
                                        id="message"
                                        name="message"
                                        rows="4"
                                        className="w-full p-3 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        placeholder="Your Message"
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        required
                                    ></textarea>
                                </div>
                            </div>
                            <div className="text-center mt-6">
                                <motion.button
                                    type="submit"
                                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded"
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                >
                                    Send Message
                                </motion.button>
                            </div>
                        </form>
                    </div>
                </motion.div>
            </div>
        </motion.section>
    );
}

export default LeaveMessage;
