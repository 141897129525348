// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import { FaBars, FaTimes } from 'react-icons/fa';

// const Header = ({ isPostPage }) => {
//     const [isMenuOpen, setIsMenuOpen] = useState(false);

//     const toggleMenu = () => {
//         setIsMenuOpen(!isMenuOpen);
//     };

//     const scrollToSection = (id) => {
//         const section = document.getElementById(id);
//         section.scrollIntoView({ behavior: 'smooth' });
//         // Close the menu after clicking on a menu item on mobile
//         setIsMenuOpen(false);
//     };

//     return (
//         <header className="bg-black text-white shadow-md">
//             <div className="container mx-auto px-4 py-6 flex justify-between items-center">
//                 <div>
//                     {!isPostPage ? (
//                         <img src="https://www.sparklingapps.com/img/logo.png" alt="Company Logo" className="h-8 md:h-10 mr-4 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110" />
//                     ) : (
//                         <Link to={"/"}> 
//                             <img src="https://www.sparklingapps.com/img/logo.png" alt="Company Logo" className="h-8 md:h-10 mr-4 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110" />
//                         </Link>
//                     )}
//                 </div>
//                 <div className="md:hidden">
//                     <button className="text-white focus:outline-none" onClick={toggleMenu}>
//                         {isMenuOpen ? <FaTimes /> : <FaBars />}
//                     </button>
//                 </div>
//                 <nav className={`md:flex space-x-6 ${isMenuOpen ? 'block' : 'hidden'}`}>
//                     <ul className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-6 text-white">
//                         <li><button onClick={() => scrollToSection('products')} className="hover:text-gray-300 focus:outline-none transition duration-300 ease-in-out transform hover:scale-110">Products</button></li>
//                         <li><button onClick={() => scrollToSection('about')} className="hover:text-gray-300 focus:outline-none transition duration-300 ease-in-out transform hover:scale-110">About</button></li>
//                         <li><button onClick={() => scrollToSection('strengths')} className="hover:text-gray-300 focus:outline-none transition duration-300 ease-in-out transform hover:scale-110">Strengths</button></li>
//                         <li><button onClick={() => scrollToSection('founders')} className="hover:text-gray-300 focus:outline-none transition duration-300 ease-in-out transform hover:scale-110">Founders</button></li>
//                         <li><button onClick={() => scrollToSection('news')} className="hover:text-gray-300 focus:outline-none transition duration-300 ease-in-out transform hover:scale-110">News</button></li>
//                         <li><button onClick={() => scrollToSection('contact')} className="hover:text-gray-300 focus:outline-none transition duration-300 ease-in-out transform hover:scale-110">Contact</button></li>
//                     </ul>
//                 </nav>
//             </div>
//         </header>
//     );
// }

// export default Header;


import { useState } from "react";
import { GrClose } from "react-icons/gr";
import { GiHamburgerMenu } from "react-icons/gi";

function Header({ isHome= false }) {
    const [showMenu, setShowMenu] = useState(false);
    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        section.scrollIntoView({ behavior: 'smooth' });
        // Close the menu after clicking on a menu item on mobile
        setShowMenu(false);
    };

    return (
        <header className="flex flex-row items-center justify-between sm:justify-around mx-auto py-3  bg-black text-white">
            <a
                href="/"
            >
                <img src="https://www.sparklingapps.com/img/logo.png" alt="Company Logo" className="h-8 md:h-10 mr-4 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110" />
            </a>
            {isHome ? <>
                <nav className="hidden sm:flex justify-between items-center gap-4 font-semibold">
                    {/* <a href="#" className="hover:text-gray-500">
                    Home
                </a>
                <a href="#" className="hover:text-gray-500">
                    About
                </a>
                <a href="#" className="hover:text-gray-500">
                    Contact
                </a> */}
                    <a><button onClick={() => scrollToSection('products')} className="hover:text-gray-300 focus:outline-none transition duration-300 ease-in-out transform hover:scale-110">Products</button></a>
                    <a><button onClick={() => scrollToSection('about')} className="hover:text-gray-300 focus:outline-none transition duration-300 ease-in-out transform hover:scale-110">About</button></a>
                    <a><button onClick={() => scrollToSection('strengths')} className="hover:text-gray-300 focus:outline-none transition duration-300 ease-in-out transform hover:scale-110">Strengths</button></a>
                    <a><button onClick={() => scrollToSection('founders')} className="hover:text-gray-300 focus:outline-none transition duration-300 ease-in-out transform hover:scale-110">Founders</button></a>
                    <a><button onClick={() => scrollToSection('news')} className="hover:text-gray-300 focus:outline-none transition duration-300 ease-in-out transform hover:scale-110">News</button></a>
                    <a><button onClick={() => scrollToSection('contact')} className="hover:text-gray-300 focus:outline-none transition duration-300 ease-in-out transform hover:scale-110">Contact</button></a>

                </nav>
                <nav className="sm:hidden flex flex-col items-end gap-1 font-semibold">
                    <button
                        onClick={() => setShowMenu(!showMenu)}
                        className="sm:hidden font-bold text-xl hover:text-gray-500"
                    >
                        {showMenu ? <GrClose /> : <GiHamburgerMenu />}
                    </button>
                    {showMenu && (
                        <>
                            <a><button onClick={() => scrollToSection('products')} className="hover:text-gray-300 focus:outline-none transition duration-300 ease-in-out transform hover:scale-110">Products</button></a>
                            <a><button onClick={() => scrollToSection('about')} className="hover:text-gray-300 focus:outline-none transition duration-300 ease-in-out transform hover:scale-110">About</button></a>
                            <a><button onClick={() => scrollToSection('strengths')} className="hover:text-gray-300 focus:outline-none transition duration-300 ease-in-out transform hover:scale-110">Strengths</button></a>
                            <a><button onClick={() => scrollToSection('founders')} className="hover:text-gray-300 focus:outline-none transition duration-300 ease-in-out transform hover:scale-110">Founders</button></a>
                            <a><button onClick={() => scrollToSection('news')} className="hover:text-gray-300 focus:outline-none transition duration-300 ease-in-out transform hover:scale-110">News</button></a>
                            <a><button onClick={() => scrollToSection('contact')} className="hover:text-gray-300 focus:outline-none transition duration-300 ease-in-out transform hover:scale-110">Contact</button></a>
                        </>
                    )}
                </nav>
            </> : <></>}
        </header>
    );
}

export default Header;

