import React, { useEffect } from 'react';
import Header from './Header';

const AllLatestNews = ({ posts }) => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page when the component mounts
    }, []);

    return (
        <>
            <Header isPostPage={true} />
            <div className="container mx-auto py-16">
                <h2 className="text-4xl font-bold mb-10 text-center text-gradient">Latest News</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {posts.map(post => (
                        <a key={post.id} href={post.link} target="_blank" rel="noopener noreferrer" className="block">
                            <div className="bg-gray-900 rounded-lg overflow-hidden shadow-lg transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105">
                                <div className="relative">
                                    {post.type.includes('youtube') ? (
                                        <iframe
                                            title={`Video ${post.title}`}
                                            src={post.link}
                                            className="w-full h-64"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        />
                                    ) : <>
                                        {
                                            post.type.includes('linkedin') ? (
                                                <img src={`https://upload.wikimedia.org/wikipedia/commons/thumb/0/01/LinkedIn_Logo.svg/1024px-LinkedIn_Logo.svg.png`} alt={post.title} className="bg-gray-300 w-full h-64 object-contain px-2" />
                                            ) : (
                                                // <img src={`https://source.unsplash.com/800x600/?nature,${post.id}`} alt={post.title} className="w-full h-64 object-contain px-2" />
                                                <img src={`${post.image}`} alt={post.title} className="w-full h-64 object-contain px-2" />

                                            )
                                        }
                                    </>

                                    }
                                    <div className="absolute inset-0 flex items-center justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-white opacity-0 group-hover:opacity-100 transition duration-300 ease-in-out" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M10 3a1 1 0 0 0-1 1v12a1 1 0 1 0 2 0V4a1 1 0 0 0-1-1z" clipRule="evenodd" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="p-6">
                                    <h3 className="text-xl font-semibold mb-2 text-white">{post.title}</h3>
                                    <p className="text-gray-400 mb-4">{post.content}</p>
                                    <p className="text-sm text-gray-500 mb-2">{post.date}</p>
                                    <a href={post.link} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">Read more</a>
                                </div>
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </>
    );
};

export default AllLatestNews;
