import { motion } from 'framer-motion';
import React from 'react';
import { Link } from 'react-router-dom';

const LatestNews = ({ posts }) => {
    return (
        <motion.section
            id='news'
            className="bg-gray-900 text-white py-20"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
        >
            <div className="container mx-auto px-6 lg:px-8">
                <h2 className="text-4xl font-bold mb-12 text-center text-gradient">Latest News</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
                    {posts.map(post => (
                        <motion.div
                            key={post.id}
                            className="bg-gray-800 rounded-lg overflow-hidden shadow-lg transform transition-transform duration-300 hover:scale-105"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            <a href={post.link} target="_blank" rel="noopener noreferrer" className="block h-full">
                                {post.type.includes('youtube') ? (
                                    <iframe
                                        title={`Video ${post.id}`}
                                        src={post.link}
                                        className="w-full h-72"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    />
                                ) : post.type.includes('linkedin') ? (
                                    <img
                                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/01/LinkedIn_Logo.svg/1024px-LinkedIn_Logo.svg.png"
                                        alt={post.title}
                                        className="bg-gray-300 w-full h-72 object-contain px-2"
                                    />
                                ) : (
                                    <img
                                        src={post.image}
                                        alt={post.title}
                                        className="w-full h-72 object-contain px-2"
                                    />
                                )}
                                <div className="p-6 flex flex-col h-full">
                                    <h3 className="text-xl font-semibold mb-4">{post.title}</h3>
                                    <p className="text-gray-400 mb-4 flex-grow">{post.content}</p>
                                    <div className="flex justify-between items-end mt-4">
                                        <p className="text-sm text-gray-500">{post.date}</p>
                                        <Link to={`/news/${post.id}`} className="text-blue-500 hover:underline">Read more</Link>
                                    </div>
                                </div>
                            </a>
                        </motion.div>
                    ))}
                </div>
                <div className="text-center mt-12">
                    <Link className="bg-blue-500 hover:bg-blue-600 text-white px-8 py-4 rounded-full shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500" to={`/news`}>View All News</Link>
                </div>
            </div>
        </motion.section>
    );
};

export default LatestNews;
