import React from 'react';
import Slider from 'react-slick';
import ProductContainer from './ProductContainer'; // Adjust this import according to your file structure
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { motion } from 'framer-motion';

const ProductSlideshow = ({ categories }) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
    };

    return (
        <div className="text-white mt-8 px-8">
            <Slider {...settings}>
                {categories.map((category, index) => (
                    <motion.div
                        key={index}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        className="flex justify-center" // Center the ProductContainer horizontally
                    >
                        {/* {console.log("category: ", category.title)} */}

                        <ProductContainer title={category.title} products={category.products} />
                    </motion.div>
                ))}
            </Slider>
        </div>
    );
}

export default ProductSlideshow;
