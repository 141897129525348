import React, { useState, useEffect } from 'react';
import { auth, database } from '../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { ref, set } from 'firebase/database';
import { v4 as uuidv4 } from 'uuid';
import {
    Container,
    TextField,
    Button,
    MenuItem,
    Grid,
    Typography,
    Paper,
    Snackbar,
    Alert,
    Box,
} from '@mui/material';
import Header from './Header';

const UploadNews = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [datetime, setDatetime] = useState('');
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [link, setLink] = useState('');
    const [type, setType] = useState('');
    const [image, setImage] = useState('');
    const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState({ severity: 'success', message: '' });

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                setIsAuthenticated(true);
            } else {
                setIsAuthenticated(false);
            }
        });

        return () => unsubscribe();
    }, []);

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, email, password);
            setIsAuthenticated(true);
        } catch (error) {
            setAlert({ severity: 'error', message: 'Login failed. Please check your credentials.' });
            setOpen(true);
        }
    };

    const handleUpload = async (e) => {
        e.preventDefault();

        const id = uuidv4(); // Generate a unique ID
        const newsData = {
            datetime,
            title,
            content,
            link,
            type,
            ...(type === 'other' && { image }), // Include image only if type is 'other'
        };

        try {
            await set(ref(database, `news/${id}`), newsData);
            setAlert({ severity: 'success', message: 'News uploaded successfully!' });
        } catch (error) {
            console.error('Error uploading news:', error);
            setAlert({ severity: 'error', message: 'Failed to upload news.' });
        } finally {
            setOpen(true);
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <>
            <Header isHome={false} />
            <Container maxWidth="sm" style={{ marginTop: '2rem' }}>
                <Paper elevation={3} style={{ padding: '2rem' }}>
                    {isAuthenticated ? (
                        <Box>
                            <Typography variant="h4" gutterBottom align="center">
                                Upload News
                            </Typography>
                            <form onSubmit={handleUpload}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Title"
                                            type="text"
                                            fullWidth
                                            variant="outlined"
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Content"
                                            multiline
                                            rows={4}
                                            fullWidth
                                            variant="outlined"
                                            value={content}
                                            onChange={(e) => setContent(e.target.value)}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Date"
                                            type="date"
                                            fullWidth
                                            variant="outlined"
                                            value={datetime}
                                            onChange={(e) => setDatetime(e.target.value)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Link"
                                            type="url"
                                            fullWidth
                                            variant="outlined"
                                            value={link}
                                            onChange={(e) => setLink(e.target.value)}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Type"
                                            select
                                            fullWidth
                                            variant="outlined"
                                            value={type}
                                            onChange={(e) => setType(e.target.value)}
                                            required
                                        >
                                            <MenuItem value="linkedin">LinkedIn</MenuItem>
                                            <MenuItem value="youtube">YouTube</MenuItem>
                                            <MenuItem value="other">Other</MenuItem>
                                        </TextField>
                                    </Grid>
                                    {type === 'other' && (
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Image URL"
                                                fullWidth
                                                variant="outlined"
                                                value={image}
                                                onChange={(e) => setImage(e.target.value)}
                                                required
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={12} align="center">
                                        <Button type="submit" variant="contained" color="primary" size="large">
                                            Upload News
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Box>
                    ) : (
                        <Box>
                            <Typography variant="h4" gutterBottom align="center">
                                Login to Upload News
                            </Typography>
                            <form onSubmit={handleLogin}>
                                <Grid container spacing={3} justifyContent="center">
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Email"
                                            type="email"
                                            fullWidth
                                            variant="outlined"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Password"
                                            type="password"
                                            fullWidth
                                            variant="outlined"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} align="center">
                                        <Button type="submit" variant="contained" color="primary" size="large">
                                            Login
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Box>
                    )}
                </Paper>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={alert.severity} sx={{ width: '100%' }}>
                        {alert.message}
                    </Alert>
                </Snackbar>
            </Container>
        </>);
};

export default UploadNews;
