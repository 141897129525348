import React from 'react';
import { motion } from 'framer-motion';
import teamImage from "../raw/spark_team_2024.jpg"
// Placeholder image URLs
const martijnImage = 'https://www.sparklingapps.com/team/wp-content/uploads/2014/06/model-1.png';

const MeetTheTeam = () => {
    return (
        <motion.div
            id='founders'
            className="bg-cover bg-center text-white py-10 min-h-screen flex items-center justify-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
        >
            <div className="bg-black bg-opacity-70 py-6 px-8 w-full">
                <div className="max-w-6xl mx-auto">
                    <div className="mb-16 text-center">
                        <h1 className="text-5xl font-bold mb-4">Meet the Team</h1>
                        <p className="text-xl">The founder has an exceptional technical background and has over 35 years of experience in software development.</p>
                    </div>
                    <div className="flex flex-col gap-8 sm:flex-row sm:justify-center">
                        <motion.div
                            className="flex flex-1 flex-col items-center p-8 rounded-lg bg-gradient-to-br from-blue-500 to-purple-500 text-white shadow-lg"
                            whileHover={{ scale: 1.05 }}
                        >
                            <img src={martijnImage} alt="Martijn van der Spek" className="rounded-full w-48 h-48 mb-4 object-cover text-center" />
                            <h2 className="text-3xl font-bold mb-2 text-center">Martijn van der Spek</h2>
                            <h3 className="text-xl font-semibold mb-4 text-center">Managing Director</h3>
                            <p className="leading-relaxed text-center">
                                Martijn van der Spek used his strong basis, obtaining a Masters in Computer Science from Delft University, the top Technical University in The Netherlands, to enable him with the skills that he used to build up software development teams across the globe. Managing Sparkling Apps has been an incredible experience for him, and he is looking forward to taking this venture to further new heights.
                            </p>
                        </motion.div>
                        <motion.div
                            className="flex flex-1 flex-col items-center p-8 rounded-lg bg-gradient-to-br from-pink-500 to-red-500 text-white shadow-lg"
                            whileHover={{ scale: 1.05 }}
                        >
                            <img src={teamImage} alt="Our Team" className="rounded-full w-48 h-48 mb-4 object-center" />
                            <h2 className="text-3xl font-bold mb-2 text-center">Sparkling Apps</h2>
                            <h3 className="text-xl font-semibold mb-4 text-center">Our Team</h3>
                            <p className="leading-relaxed text-center">
                                Sparkling Apps is a young enterprise that has jumped on the possibilities in the current market and technologies. The mobile technology and social media offer unique opportunities. The strength of Sparkling Apps lies in offering excellent quality in an economical package.
                            </p>
                        </motion.div>
                    </div>
                </div>
            </div>
        </motion.div>
    );
}

export default MeetTheTeam;
