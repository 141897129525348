// AppSection.js
import React from 'react';
import AppCallRecorder from "../raw/AppCallRecorder.png";
import ImagAIne from "../raw/ImagAIne.png";
import ImageAI from "../raw/ImageAI.png";
import ImageCast from "../raw/ImageCast.png";
import InstaCast from "../raw/InstaCast.png";
import KeyboardTranslator from "../raw/KeyboardTranslator.png";
import MessengerCallRecorder from '../raw/MessengerCallRecorder.png';
import Notams from "../raw/Notams.png";
import PanzerBattle from "../raw/PanzerBattle.png";
import Phoice from "../raw/Phoice.png";
import PiccyBot from "../raw/PiccyBot.png";
import PilotWeather from "../raw/PilotWeather.png";
import RedmiCallRecorder from "../raw/RedmiCallRecorder.png";
import TalkingGoggles from "../raw/Talking Goggles.png";
import TranslatorWithSpeech from "../raw/TranslatorWithSpeech.png";
import Travis from "../raw/Travis.png";
import TuneCast from "../raw/TuneCast.png";
import UniversalCallRecorder from "../raw/UniversalCallRecorder.png";
import UpscaleAI from "../raw/UpscaleAI.png";
import VideoCast from "../raw/VideoCast.png";
import VoiceAnswer from "../raw/VoiceAnswer.png";
import VoiceArt from '../raw/VoiceArt.png';
import VoiceToVoice from "../raw/VoiceToVoice.png";
import WeatherRadar from "../raw/WeatherRadar.png";
import ProductSlideshow from './ProductSlideshow';

const smartTVApps = [
    {
        id: 1,
        name: 'VideoCast',
        url: 'https://play.google.com/store/apps/details?id=com.sparkling.videocast',
        logo: VideoCast
    },
    {
        id: 2,
        name: 'TuneCast',
        url: 'https://play.google.com/store/apps/details?id=com.sparklingapps.tunecast',
        logo: TuneCast
    },
    // {
    //     name: 'NetCast',
    //     url: 'https://play.google.com/store/apps/details?id=com.sparklingapps.netcastapp',
    //     logo: "https://www.sparklingapps.com/newsparklingapps/in/img/portfolio/netcast2.png"
    // },
    {
        id: 3,
        name: 'ImageCast',
        url: 'https://apps.apple.com/us/app/imagecast-pro/id1169340860',
        logo: ImageCast
    },

]

const languageApps = [
    {
        id: 4,
        name: 'Translator With Speech',
        url: "https://www.sparklingapps.com/translator/",
        logo: TranslatorWithSpeech
    },
    {
        id: 5, name: 'Keyboard Translator',
        url: 'https://play.google.com/store/apps/details?id=com.sparklingapps.translatorkeyboard',
        logo: KeyboardTranslator
    },
    {
        id: 6,
        name: 'Voice to Voice',
        url: "https://www.sparklingapps.com/interpreter/",
        logo: VoiceToVoice
    },

]
const callRecorders = [
    {
        id: 7,
        name: 'App Call Recorder',
        url: 'https://play.google.com/store/apps/details?id=com.sparklingapps.callrecorder',
        logo: AppCallRecorder
    },
    {
        id: 8,
        name: 'Universal Call Recorder',
        url: 'https://play.google.com/store/apps/details?id=com.sparklingapps.callrecorder.full',
        logo: UniversalCallRecorder
    },
    {
        id: 9,
        name: 'Messenger Call Recorder',
        url: 'https://play.google.com/store/apps/details?id=com.sparklingapps.callrecorder.messenger',
        logo: MessengerCallRecorder
    },
    {
        id: 10,
        name: 'Redmi Call Recorder',
        url: 'https://play.google.com/store/apps/details?id=com.sparklingapps.callrecorder.redmi',
        logo: RedmiCallRecorder
    },

]
const aiApps = [

    {
        id: 11,
        name: 'Voice Answer',
        // url: 'https://voiceanswer.com/',
        url: "https://play.google.com/store/apps/details?id=com.proxy.voiceanswer",
        logo: VoiceAnswer
    },
    // {
    //     name: 'Voice Answer Lite',
    //     // url: 'https://voiceanswer.com/',
    //     androidLink: "https://play.google.com/store/apps/details?id=com.proxy.voiceanswerfree",
    //     logo: VoiceAnswerLite
    // },

];
const aiImageApps = [

    {
        id: 12,
        name: 'VoiceArt',
        url: 'https://sparklingapps.com/voiceart',
        // androidLink: "https://play.google.com/store/apps/details?id=com.proxy.voiceanswer",
        logo: VoiceArt
    },
    {
        id: 13,
        name: 'ImagAIne',
        url: 'https://sparklingapps.com/imagaine',
        // androidLink: "https://play.google.com/store/apps/details?id=com.proxy.voiceanswer",
        logo: ImagAIne
    },
    {
        id: 14,
        name: 'ImageAI - AI Art Generator',
        // url: 'https://sparklingapps.com/imageai',
        url: "https://play.google.com/store/apps/details?id=com.sparklingapps.imageai",
        logo: ImageAI
    },
    {
        id: 15,
        name: 'UpscaleAI',
        url: 'https://sparklingapps.com/upscaleai',
        // androidLink: "https://play.google.com/store/apps/details?id=com.proxy.voiceanswer",
        logo: UpscaleAI
    },

    // {
    //     name: 'Voice Answer Lite',
    //     // url: 'https://voiceanswer.com/',
    //     androidLink: "https://play.google.com/store/apps/details?id=com.proxy.voiceanswerfree",
    //     logo: VoiceAnswerLite
    // },

];

const weatherApps = [
    // {
    //     name: 'Weather Radar',
    //     url: 'https://apps.apple.com/us/app/weatherradar-basic/id1187807450',
    //     logo: "https://www.sparklingapps.com/img/portfolio/71.png"
    // },
    // {
    //     name: "SkyMET",
    //     url: "https://www.sky-met.com/",
    //     logo: "https://www.sparklingapps.com/newsparklingapps/in/img/portfolio/09.png"
    // },
    {
        id: 16,
        name: 'Pilot Weather',
        url: 'https://play.google.com/store/apps/details?id=nl.mercatorgeo.aeroweather',
        logo: PilotWeather
    },
    {
        id: 17,
        name: 'NOTAMs',
        url: 'https://play.google.com/store/apps/details?id=com.sparklingapps.notams',
        logo: Notams
    }
];
const games = [
    {
        id: 18,
        name: 'Panzer Battle',
        url: 'https://www.sparklingapps.com/panzerbattle/',
        // androidLink: 'https://play.google.com/store/apps/details?id=com.sparklingapps.panzerbattle',
        // iosLink: "https://apps.apple.com/us/app/panzer-battle/id1620308483",
        logo: PanzerBattle
    },
];
const lowVisionApps = [
    {
        id: 19,
        name: 'PiccyBot',
        url: 'https://www.sparklingapps.com/PiccyBot/',
        logo: PiccyBot,
        // androidLink: "https://play.google.com/store/apps/details?id=com.sparklingapps.piccybot",
        // iosLink: "https://apps.apple.com/us/app/piccybot/id6476859317"
    },
    {
        id: 20,
        name: 'Phoice',
        url: 'https://www.sparklingapps.com/phoice/',
        logo: Phoice,
        // androidLink: "https://play.google.com/store/apps/details?id=com.sparklingapps.phoice",
        // iosLink: "https://apps.apple.com/in/app/phoice/id6472152960"

    },
    {
        id: 21,
        name: 'Talking Goggles',
        url: 'https://www.sparklingapps.com/goggles/',
        logo: TalkingGoggles,
        // androidLink: "https://play.google.com/store/apps/details?id=com.sparkling.goggles",
    },
    // {
    //     name: 'Talking Goggles Lite',
    //     // url: 'https://www.sparklingapps.com/goggles/',
    //     logo: TalkingGogglesLite,
    //     androidLink: "https://play.google.com/store/apps/details?id=com.sparkling.talkinggogglesfree",
    // },
]
const clientApps = [
    // {
    //     name: 'Easy Spelling Aid',
    //     url: 'https://www.easyspellingaid.com/',
    //     logo: "https://www.sparklingapps.com/img/portfolio/16.png"
    // },
    // {
    //     name: 'Hire Wire',
    //     url: 'https://hirewire.online/',
    //     logo: "https://www.sparklingapps.com/newsparklingapps/in/img/portfolio/hirewire2.png"
    // },
    {
        id: 22,
        name: 'Travis',
        url: 'https://www.travistranslator.com/',
        logo: Travis
    },
];
const macOsApps = [
    // {
    //     name: 'MediaCenter',
    //     url: 'https://itunes.apple.com/us/app/mediacenter-for-netflix-more/id1144474015?mt=12',
    //     logo: "https://www.sparklingapps.com/newsparklingapps/in/img/portfolio/51.png"
    // },
    {
        id: 23,
        name: 'InstaCast',
        url: 'https://apps.apple.com/us/app/imagecast/id1192144157?mt=12',
        logo: InstaCast
    },
    {
        id: 24,
        name: 'Weather Radar',
        url: 'https://apps.apple.com/us/app/weatherradar/id1149771398?ls=1&mt=12',
        logo: WeatherRadar
    },
    {
        id: 25,
        name: "Panzer Battle",
        url: 'https://www.sparklingapps.com/panzerbattle/',
        logo: PanzerBattle
    }
];

const oneAppCategory = [
    { title: "Intelligent Assistants", products: aiApps },
    { title: "Apps for Gaming and Children", products: games },
    { title: "Distinctive Apps for a Wide Variety of Clients", products: clientApps },
    { title: "Specialized in Weather and Aviation", products: weatherApps },
]

// const twoAppsCategory = [
//     { title: "Specialized in Weather and Aviation", products: weatherApps },

// ]
const threeAppsCategory = [
    { title: "Apps to Enhance Your Smart TV Experience", products: smartTVApps },
    { title: "Products to Overcome Language Barriers", products: languageApps },
    { title: "Support for Blind and Low Vision Users", products: lowVisionApps },
    { title: "Apps for Mac OS X", products: macOsApps },
    { title: "Apps for Recording Calls", products: callRecorders },
    { title: "AI Image Assistants", products: aiImageApps },
];

// const fourAppsCategory = [

// ]

const AppSection = () => {
    return (
        <div className="bg-gray-900">

            <ProductSlideshow categories={oneAppCategory} />
            {/* <ProductContainer title={twoAppsCategory[0].title} products={twoAppsCategory[0].products} /> */}
            <ProductSlideshow categories={threeAppsCategory} />
            {/* <ProductSlideshow categories={fourAppsCategory} /> */}

        </div>);
}

export default AppSection;
