import React from 'react';
import { FaFacebook, FaApple, FaYoutube, FaLinkedin } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa6';
import { motion } from 'framer-motion';

const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <motion.footer 
            className="bg-gray-800 text-white py-6"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
        >
            <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
                <div className="mb-4 md:mb-0 text-center md:text-left">
                    <span className="text-sm">&copy; {currentYear} Sparkling Apps. All Rights Reserved. 
                        <a href="https://www.sparklingapps.com/privacy.html" className="text-blue-300 hover:text-blue-500 ml-2">Privacy Policy</a>
                    </span>
                </div>
                <div className="flex space-x-4">
                    <motion.a 
                        href="https://www.facebook.com/people/Sparkling-Apps/100063547334177/" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="text-white hover:text-blue-400"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                    >
                        <FaFacebook size={20} />
                    </motion.a>
                    <motion.a 
                        href="https://itunes.apple.com/in/artist/sparkling-apps/id364938829" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="text-white hover:text-blue-400"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                    >
                        <FaApple size={20} />
                    </motion.a>
                    <motion.a 
                        href="https://www.youtube.com/user/sparklingapps/videos" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="text-white hover:text-blue-400"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                    >
                        <FaYoutube size={20} />
                    </motion.a>
                    <motion.a 
                        href="https://twitter.com/spark215bv?lang=en" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="text-white hover:text-blue-400"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                    >
                        <FaTwitter size={20} />
                    </motion.a>
                    <motion.a 
                        href="https://www.linkedin.com/company/sparkling-design-and-infotech" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="text-white hover:text-blue-400"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                    >
                        <FaLinkedin size={20} />
                    </motion.a>
                </div>
            </div>
        </motion.footer>
    );
}

export default Footer;
