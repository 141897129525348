import { motion } from 'framer-motion';
import React from 'react';

const HowToReachUs = () => {

    return (
        <motion.div
            id='contact'
            className="bg-gray-900 text-white py-12 px-4 sm:px-8 lg:px-16 xl:px-20"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
        >
            <div className="max-w-6xl mx-auto text-black">
                <h2 className="text-4xl font-bold mb-6 text-center text-white">How to Reach Us</h2>
                <p className="text-center mb-8 text-white">Reach out to us and we'll respond as soon as we can.</p>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
                    <motion.div
                        className="flex flex-col items-center bg-gray-200 rounded-lg overflow-hidden shadow-lg"
                        whileHover={{ scale: 1.05 }}
                    >
                        <iframe
                            title='Sparkling Apps B.V.'
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2447.5344342089866!2d5.3838490000000006!3d52.160976!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c646a3a4e0de2f%3A0xa183af605fa5bcfa!2sDrapiersgilde%2031%2C%203813%20GT%20Amersfoort%2C%20Netherlands!5e0!3m2!1sen!2sus!4v1716981438277!5m2!1sen!2sus"
                            width="100%"
                            height="250"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                        ></iframe>
                        <div className="p-6 text-center">
                            <h3 className="text-2xl font-semibold mb-2">Sparkling Apps B.V.</h3>
                            <p className="mb-2">Drapiersgilde 31, 3813GT Amersfoort</p>
                            <p className="mb-2">The Netherlands</p>
                            <p className="mb-2"><a href="mailto:support@sparklingapps.com" className="text-blue-400">support@sparklingapps.com</a></p>
                            <p className="mb-2">+31 654 7060 96</p>
                        </div>
                    </motion.div>
                    <motion.div
                        className="flex flex-col items-center bg-gray-200 rounded-lg overflow-hidden shadow-lg"
                        whileHover={{ scale: 1.05 }}
                    >
                        <iframe
                            title='Sparkling Apps'
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3945.3720770862365!2d76.879491!3d8.560174!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b05befb8da731d7%3A0x214e7a72d4870ded!2sSparkling%20Apps!5e0!3m2!1sen!2sus!4v1716981117713!5m2!1sen!2sus"
                            width="100%"
                            height="250"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                        ></iframe>
                        <div className="p-6 text-center">
                            <h3 className="text-2xl font-semibold mb-2 ">Sparkling Apps</h3>
                            <p className="mb-2">Nila 215, Technopark,</p>
                            <p className="mb-2">Thiruvananthapuram, Kerala 695581</p>
                            <p className="mb-2"><a href="mailto:support@sparklingapps.com" className="text-blue-400">support@sparklingapps.com</a></p>
                            <p className="mb-2">+91 98950 47626</p>
                        </div>
                    </motion.div>
                </div>
            </div>
        </motion.div>
    );
}

export default HowToReachUs;
