import React from 'react';
import { FaRocket, FaLaptopCode, FaGem } from 'react-icons/fa';
import { motion } from 'framer-motion';

const AboutUs = () => {
    return (
        <motion.div
            id='about'
            className="bg-cover bg-center text-white py-10"
            // className="bg-gray-900 text-white py-10"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
        >
            <div className="max-w-6xl mx-auto">
                <div className="mb-16 text-center">
                    <h1 className="text-5xl font-bold mb-4">About Us</h1>
                    <p className="text-xl">Discover who we are, what we do, and why you should choose us.</p>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    <motion.div
                        className="p-8 bg-gray-800 rounded-lg shadow-lg text-center"
                        whileHover={{ scale: 1.05 }}
                    >
                        <FaRocket className="text-6xl mb-4 text-green-500 mx-auto" />
                        <h2 className="text-3xl font-bold mb-4">Who We Are</h2>
                        <p className="leading-relaxed">
                            Sparkling Apps is a forward-thinking enterprise leveraging current market trends and technologies. Our focus lies in developing applications that capitalize on mobile technology and social media, particularly in the field of software recognition. Led by Martijn van der Spek, our founder with over 35 years of software development experience, we bring exceptional technical expertise to every project.
                        </p>
                    </motion.div>
                    <motion.div
                        className="p-8 bg-gray-800 rounded-lg shadow-lg text-center"
                        whileHover={{ scale: 1.05 }}
                    >
                        <FaLaptopCode className="text-6xl mb-4 text-blue-500 mx-auto" />
                        <h2 className="text-3xl font-bold mb-4">What We Do</h2>
                        <p className="leading-relaxed">
                            At Sparkling Apps, we combine our passion for technology with our understanding of partner requirements and extensive experience in outsourcing. This unique blend enables us to offer excellent quality solutions in a cost-effective package. We specialize in delivering innovative, high-quality products tailored to our clients' needs.
                        </p>
                    </motion.div>
                    <motion.div
                        className="p-8 bg-gray-800 rounded-lg shadow-lg text-center"
                        whileHover={{ scale: 1.05 }}
                    >
                        <FaGem className="text-6xl mb-4 text-purple-500 mx-auto" />
                        <h2 className="text-3xl font-bold mb-4">Why Choose Us</h2>
                        <p className="leading-relaxed">
                            Despite being a small and agile team, Sparkling Apps operates a full development center in India under permanent Dutch management. This unique setup, combined with our unwavering commitment to quality and innovation, sets us apart. Choose Sparkling Apps for exceptional solutions that exceed your expectations.
                        </p>
                    </motion.div>
                </div>
            </div>
        </motion.div>
    );
}

export default AboutUs;
