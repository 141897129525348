import React from 'react';
import { FaMobileAlt, FaApple, FaMoneyBillWave, FaPalette } from 'react-icons/fa';
import { motion } from 'framer-motion';

const OurStrengths = () => {
    return (
        <motion.div 
            id='strengths' 
            className="bg-cover bg-center text-white py-10"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
        >
            <div className="bg-black bg-opacity-70 py-8 px-4 sm:px-8 lg:px-16 xl:px-20">
                <div className="max-w-6xl mx-auto">
                    <div className="mb-16 text-center">
                        <h1 className="text-5xl font-bold mb-4">Our Strengths</h1>
                        <p className="text-xl">Discover what sets us apart in the industry.</p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                        <motion.div 
                            className="flex flex-col items-center p-6 rounded-lg bg-gradient-to-br from-green-400 to-green-600 shadow-lg"
                            whileHover={{ scale: 1.05 }}
                        >
                            <FaMobileAlt className="text-6xl mb-4 text-white" />
                            <h2 className="text-3xl font-bold mb-4 text-center">Mobile App Development</h2>
                            <p className="leading-relaxed text-center text-white">
                                We specialize in crafting high-quality mobile applications tailored to modern user needs.
                            </p>
                        </motion.div>
                        <motion.div 
                            className="flex flex-col items-center p-6 rounded-lg bg-gradient-to-br from-blue-400 to-blue-600 shadow-lg"
                            whileHover={{ scale: 1.05 }}
                        >
                            <FaApple className="text-6xl mb-4 text-white" />
                            <h2 className="text-3xl font-bold mb-4 text-center">iOS Development</h2>
                            <p className="leading-relaxed text-center text-white">
                                Our team boasts extensive expertise in iOS development, delivering outstanding apps for Apple devices.
                            </p>
                        </motion.div>
                        <motion.div 
                            className="flex flex-col items-center p-6 rounded-lg bg-gradient-to-br from-yellow-400 to-yellow-600 shadow-lg"
                            whileHover={{ scale: 1.05 }}
                        >
                            <FaMoneyBillWave className="text-6xl mb-4 text-white" />
                            <h2 className="text-3xl font-bold mb-4 text-center">Cost-Effective Solutions</h2>
                            <p className="leading-relaxed text-center text-white">
                                We offer economical solutions without compromising on quality, ensuring excellent value for money.
                            </p>
                        </motion.div>
                        <motion.div 
                            className="flex flex-col items-center p-6 rounded-lg bg-gradient-to-br from-purple-400 to-purple-600 shadow-lg"
                            whileHover={{ scale: 1.05 }}
                        >
                            <FaPalette className="text-6xl mb-4 text-white" />
                            <h2 className="text-3xl font-bold mb-4 text-center">European Design Influence</h2>
                            <p className="leading-relaxed text-center text-white">
                                Our designs are infused with European aesthetics, bringing a unique and captivating look to our apps.
                            </p>
                        </motion.div>
                    </div>
                </div>
            </div>
        </motion.div>
    );
}

export default OurStrengths;
