import { motion } from 'framer-motion';
import React from 'react';

const ProductContainer = ({ title, products }) => {
    // console.log("products:", products.title);
    // Determine the number of columns based on the number of products
    const getGridColumns = () => {
        if (products.length === 1) return 'grid-cols-1';
        if (products.length === 2) return 'grid-cols-2';
        if (products.length === 3) return 'lg:grid-cols-3 md:grid-cols-2 grid-cols-2';
        if (products.length === 4) return 'lg:grid-cols-4 md:grid-cols-2 grid-cols-2';
        return 'grid-cols-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4';
    };

    return (
        
        <div className="bg-gray-900 text-white py-12 px-4 sm:px-8 lg:px-16 xl:px-20">
        {/* {console.log(title)} */}
            <h1 className="text-4xl font-bold mb-8 text-center">{title}</h1>
            <div className={`grid gap-8 mx-auto ${getGridColumns()} justify-items-center`}>
                {products.map((product, index) => (
                    <motion.div
                        key={index}
                        className="rounded-lg overflow-hidden transform transition duration-500 hover:scale-105 hover:shadow-neon hover:border-neon hover:border-2 border-blue-600"
                        initial={{ opacity: 0, scale: 0.9 }}
                        animate={{ opacity: 1, scale: 1 }}
                        whileHover={{ scale: 1.05 }}
                    >
                        <a href={product.url} target="_blank" rel="noopener noreferrer" className="block p-6">
                            <img src={product.logo} alt={product.name} className="w-full h-60 object-contain mb-4 rounded-lg" />
                            <p className="text-xl font-semibold mb-4 text-center">{product.name}</p>
                        </a>
                    </motion.div>
                ))}
            </div>
        </div>
    );
}

export default ProductContainer;
