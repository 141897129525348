import React from 'react';
import video from "../raw/sparkling_intro_new.mp4";

const Video = () => {
    return (
        <div className="flex justify-center">
            <video className="w-auto h-1/2" autoPlay loop muted>
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    );
}

export default Video;
