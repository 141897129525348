import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ref, get, child } from 'firebase/database';
import { database } from './firebase'; // Assuming you have firebase initialized correctly
import SparklingApps from './components/SparklingApps';
import AllLatestNews from './components/AllLatestNews';
import UploadNews from './components/UploadNews';
import ErrorPage from './components/ErrorPage';
import './index.css'; // Import your custom CSS file
// Sample LinkedIn posts data
const posts = [
  {
    id: 1,
    title: "Enhancing Accessibility: Introducing Spoken Video Descriptions in PiccyBot!",
    content: "PiccyBot now offers spoken video descriptions for blind and low vision users.",
    date: "May 26, 2024",
    link: "https://www.linkedin.com/feed/update/urn:li:activity:7200107285933641728/"
  },
  {
    id: 2,
    title: "User Testimonials Highlight PiccyBot's Impact!",
    content: "User testimonials praise PiccyBot for its life-changing impact on blind users.",
    date: "1 Month Ago",
    link: "https://www.linkedin.com/feed/update/urn:li:activity:7185553960173203456/"
  },
  {
    id: 3,
    title: "AI Empowering the Lesser Abled: A Revolution in Accessibility!",
    content: "AI apps from Sparkling Apps empower blind and low vision users with enhanced accessibility.",
    date: "2 Months Ago",
    link: "https://www.linkedin.com/posts/vanderspek_piccybot-activity-7170668346055516160-3iKB?utm_source=share&utm_medium=member_desktop"
  },
  {
    id: 4,
    title: "Introducing the PiccyBot app!",
    content: "This video introduces the PiccyBot app for blind and low vision users, with a special focus on the new video description mode in it.",
    date: "2 Months Ago",
    link: "https://www.youtube.com/embed/AnigBgi-_Ow"
  },
  // Add more sample posts as needed
];


const Index = () => {
  const [posts, setPosts] = useState([]);
// Function to convert YouTube video link
const convertToEmbedLink = (link) => {
  // Extract the video ID from the link
  const videoId = link.split('v=')[1];
  if (videoId) {
    // Construct the embed link
    return `https://www.youtube.com/embed/${videoId}`;
  } else {
    // Handle cases where link format is invalid or doesn't contain video ID
    console.error('Invalid YouTube video link:', link);
    return null;
  }
};

  useEffect(() => {
    const fetchNews = async () => {
      const newsRef = ref(database, 'news');
      try {
        const snapshot = await get(newsRef);
        if (snapshot.exists()) {
          const fetchedPosts = [];
          snapshot.forEach((childSnapshot) => {
            const post = childSnapshot.val();
            let transformedLink = post.link;
            if (post.link.includes("youtube")) {
              transformedLink = convertToEmbedLink(post.link);
            }
            fetchedPosts.push({
              id: childSnapshot.key,
              datetime: post.datetime,
              title: post.title,
              content: post.content,
              link: transformedLink,
              type: post.type,
              image: post.image || '', // Assuming 'image' is stored in Firebase
            });
          });
          const sortedPosts = fetchedPosts.sort((a, b) => new Date(b.datetime) - new Date(a.datetime));
          setPosts(sortedPosts);
        } else {
          console.log('No data available');
        }
      } catch (error) {
        console.error('Error fetching news:', error);
      }
    };

    fetchNews();
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <SparklingApps posts={posts} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/news",
      element: <AllLatestNews posts={posts} />,
      errorElement: <ErrorPage />
    },
    {
      path: '/upload',
      element: <UploadNews />,
      errorElement: <ErrorPage />
    }
  ]);

  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
};

ReactDOM.createRoot(document.getElementById("root")).render(<Index />);
